import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import video from './video.mp4';
import videoPoster from './video.webp';
import img1 from './1.webp';
import img2 from './2.webp';
import img3 from './3.webp';

export const RedwoodsGallery: React.FC = () => (
  <>
    <Gallery
      photos={[
        {
          src: videoPoster,
          width: 16,
          height: 9,
          alt:
            'Plays a video of a first-person scooter ride through the California Redwoods & the Lost Coast. Set to the song Wicked Games by Parra For Cuva',
          video: { src: video, width: 1080 },
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img3,
          width: 3,
          height: 2,
          alt:
            'A sunlight path through the forest cutting through a fallen redwood taller than a human',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img2,
          width: 3,
          height: 2,
          alt:
            'Founders Tree, a massive 346.1 ft tall redwood with a 40.0 ft circumference as noted by a wooden sign',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img1,
          width: 16,
          height: 9,
          alt:
            'A picture of me on my Genuine Blur 220 scooter, facing away from the camera towards a clear blue California sky on a gravel road towards the Lost Coast',
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);
