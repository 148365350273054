import React from 'react';

import { Head } from '../../components/head/head';
import { Layout } from '../../components/layout/layout';
import { Logo } from './_logo';
import thumbnail from './thumbnail.jpg';

import { Year2021 } from './2021/_index';
import { Year2020 } from './2020/_index';
import { Year2019 } from './2019/_index';
import { Year2017 } from './2017/_index';
import { Year2015 } from './2015/_index';

export const Travel: React.FC = () => (
  <>
    <Head title="Travel" path="/travel" ogImage={thumbnail} />
    <Layout logo={<Logo />}>
      <h1 className="page-intro">The Road & the Ride</h1>
      <p>
        There’s nothing quite like being on two wheels or on the open road for me. It’s the ultimate
        feeling of freedom, letting the landscape move past and leaving my worries behind. I hope to
        capture a fraction of the joy I feel while exploring the beauty the world has to offer.
      </p>

      <section className="timeline">
        <Year2021 />
        <Year2020 />
        <Year2019 />
        <Year2017 />
        <Year2015 />
      </section>
    </Layout>
  </>
);

export default Travel;
