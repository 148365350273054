import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import img1 from './1.webp';
import img2 from './2.webp';
import img3 from './3.webp';
import img4 from './4.webp';
import img5 from './5.webp';
import img6 from './6.webp';

export const LAGallery: React.FC = () => (
  <>
    <Gallery
      photos={[
        {
          src: img3,
          width: 16,
          height: 9,
          alt:
            'Picture of a boardwalk trail along the California coast (Pt Dume). A large cloud hangs overhead.',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img4,
          width: 16,
          height: 9,
          alt:
            'Picture of the Koean Friendship Bell and its pagoda. Someone is flying a squid-shaped kite against a cloudless bright blue sky.',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img1,
          width: 1,
          height: 1,
          alt:
            'Picture of my rented Vespa GTS 300 with my motorcycle helmet on the seat. Taken in a parking lot with hills and palm trees in the background.',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img2,
          width: 16,
          height: 9,
          alt:
            'Picture of a sandy trail along a dune by the California coast, with a small silhouette of a person climbing along the top. Prominently features the ocean and some wide clouds against blue skies.',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img5,
          width: 16,
          height: 9,
          alt:
            'Picture of a fisherman in a red plaid shirt and jeans along a boardwalk, holding a small recent catch.',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img6,
          width: 16,
          height: 9,
          alt:
            'Picture of a Malibu boardwalk. A small child in a purple shirt is swinging on a swing outside a tiny wooden shop with a large owl etched on its side.',
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);
