import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import img1 from './1.webp';
import img2 from './2.webp';
import img3 from './3.webp';
import img4 from './4.webp';

export const CAGallery: React.FC = () => (
  <Gallery
    photos={[
      {
        src: img1,
        width: 16,
        height: 9,
        alt:
          'A kitschy roadside sign pointing to various international cities and their distance from the sign. Next to it is a rustic log table and stool. In the distance are golden yellow hills.',
        tabIndex: 1,
        loading: 'lazy',
      },
      {
        src: img2,
        width: 3,
        height: 2,
        alt:
          'A dark and empty road, forested and blue in the early morning light. The speed limit is 40 miles and hour',
        tabIndex: 1,
        loading: 'lazy',
      },
      {
        src: img3,
        width: 3,
        height: 2,
        alt:
          'Picture of the sun rising on the California ocean and some bluffs. In the distance, the mountains are tinted pink and the sky fades from orange to blue.',
        tabIndex: 1,
        loading: 'lazy',
      },
      {
        src: img4,
        width: 16,
        height: 9,
        alt:
          'Picture of Mt. Shasta during a vivid sunset. Its snowy slopes are tinted pink amongst red and orange clouds. In the foreground are trees and a backyard fence.',
        tabIndex: 1,
        loading: 'lazy',
      },
    ]}
  />
);
