import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import videoPoster from './video.webp';
import video from './video.webm';
import img1 from './1.webp';
import img2 from './2.webp';
import img3 from './3.webp';

export const YellowstoneGallery: React.FC = () => (
  <Gallery
    photos={[
      {
        src: videoPoster,
        width: 16,
        height: 9,
        alt:
          'Plays a video of some short hyperlapse video footage on Interstate 90 between Washington and Montana set to relaxing piano and upbeat guitar music.',
        video: { src: video, width: 700 },
        tabIndex: 1,
        loading: 'lazy',
      },
      {
        src: img1,
        width: 16,
        height: 9,
        alt:
          'Picture of Lake Yellowstone with blue and serene water and with small clouds scudding the sky. A large rock and pine tree are in the foreground.',
        tabIndex: 1,
        loading: 'lazy',
      },
      {
        src: img2,
        width: 16,
        height: 9,
        alt:
          'Picture of an empty road in Yellowstone National Park curving off into the distance. The landscape is dotted by trees, mountains peak through the horizon, and a variety of clouds hang in the sky.',
        tabIndex: 1,
        loading: 'lazy',
      },
      {
        src: img3,
        width: 16,
        height: 9,
        alt:
          'Picture of a curving cliffside road in Yellowstone National Park, disappearing into the forests and protected by a guardrail on the left. A large hilltop dominates the background.',
        tabIndex: 1,
        loading: 'lazy',
      },
    ]}
  />
);
