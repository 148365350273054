import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import { CAGallery } from './12/ca_drive/_index';
import video from './video.webm';
import videoPoster from './video.webp';

export const Year2017: React.FC = () => (
  <>
    <h2 className="timeline__event">2017</h2>
    <h3 className="timeline__event">December</h3>
    <h4 className="timeline__event" id="2017-12-shasta">
      California
    </h4>
    <p>A variety of pictures while driving back up from Monterey Bay in California.</p>
    <CAGallery />
    <h3 className="timeline__event" id="2017-driving">
      Misc
    </h3>
    <p>
      A compilation of dashcam footage driving from around Washington state. I knew I lived here for
      a reason.
    </p>
    <Gallery
      photos={[
        {
          src: videoPoster,
          width: 16,
          height: 9,
          alt:
            'Plays a video of a first-person dashcam through the in various places around Washington state. Set to the song Hyperlapse by Paul Reiss',
          video: { src: video, width: 700 },
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);
