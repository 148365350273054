import React from 'react';

import { YellowstoneGallery } from './06/yellowstone/_index';

export const Year2015: React.FC = () => (
  <>
    <h2 className="timeline__event">2015</h2>
    <h3 className="timeline__event">July</h3>
    <h4 className="timeline__event" id="2015-06-yellowstone">
      Yellowstone National Park
    </h4>
    <p>
      My first road trip to Yellowstone National Park, and my first foray into roadtrip videography
      and hyperlapses.
    </p>
    <YellowstoneGallery />
  </>
);
