import React from 'react';

import { MtBaker } from './mt_baker/_index';
import { MtRainier } from './mt_rainier/_index';

export const Month08: React.FC = () => (
  <>
    <h3 className="timeline__event">August</h3>
    <MtBaker />
    <MtRainier />
  </>
);
