import React from 'react';

import { LAGallery } from './07/LA/_index';

export const Year2019: React.FC = () => (
  <>
    <h2 className="timeline__event">2019</h2>
    <h3 className="timeline__event">July</h3>
    <h4 className="timeline__event" id="2015-06-yellowstone">
      Los Angeles
    </h4>
    <p>
      I rented a Vespa 300 GTS and scooted around the greater LA area and coast. Absolutely loved
      it, and was dying to go back again to try out the mountain passes.
    </p>
    <LAGallery />
  </>
);
