import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import videoPoster from './video.webp';
import video from './video.webm';
import img1 from './1.webp';
import img2 from './2.webp';

export const MtBaker: React.FC = () => (
  <>
    <h4 className="timeline__event" id="2020-08-mt-baker">
      Mt. Baker National Park
    </h4>
    <p>A 9.5 hour round-trip to Baker Lake and back.</p>
    <Gallery
      photos={[
        {
          src: videoPoster,
          width: 16,
          height: 9,
          alt:
            'Plays a video of a first-person scooter ride through Mt. Baker National Forest. This is set to calm and peaceful guitar music, and is a slow and uninterrupted video.',
          video: { src: video, width: 1000 },
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img1,
          width: 1,
          height: 1,
          alt:
            'Picture from behind my Genuine Buddy scooter, parked near Baker Lake. You can see the lake in the distance, along with mountain ranges. In the middle ground are trees and a park information board.',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img2,
          width: 16,
          height: 9,
          alt:
            'Picture from behind my Genuine Buddy scooter, on the left shoulder of a road right before a bridge crossing a large river. A view of Mt. Baker is in the distance against a bright blue sky framed by trees',
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);
