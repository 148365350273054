import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import video from './middle_forks/video.webm';
import videoPoster from './middle_forks/video.webp';
import img1 from './165/1.webp';
import img2 from './165/2.webp';
import img3 from './165/3.webp';

export const Month07: React.FC = () => (
  <>
    <h3 className="timeline__event">July</h3>
    <h4 className="timeline__event">Middle Forks & WA-165</h4>
    <p>
      On two separate trips, I took my Buddy scooter as far east as I could go (Middle Forks, video)
      and as far south as I'd ever gone (WA-165, photos). Both times were a blast.
    </p>
    <Gallery
      photos={[
        {
          src: videoPoster,
          width: 16,
          height: 9,
          alt:
            'Plays a hyperlapse video of a first-person scooter ride through Snoqualmie Middle Forks road. This is set to upbeat music with whistling and hand claps.',
          video: { src: video, width: 700 },
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img1,
          width: 4,
          height: 3,
          alt:
            'Picture from the seat of my Buddy scooter, looking out to a large hill, a dried out riverbed, and the start of a bridge.',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img3,
          width: 4,
          height: 3,
          alt:
            'Picture of the front of my Buddy scooter parked on the shoulder of a road, along a river. Trees and mountains dominate the background.',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img2,
          width: 32,
          height: 21,
          alt:
            'Picture of a bend in Road 165, empty save for me and the forests. The sky is a brilliant blue with wisps of cloud.',
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);
