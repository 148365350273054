import React from 'react';

import { RedwoodsGallery } from './08/redwoods/_index';

export const Year2021: React.FC = () => (
  <>
    <h2 className="timeline__event">2021</h2>
    <h3 className="timeline__event">August</h3>
    <h4 className="timeline__event" id="2021-08-redwoods">
      California Redwoods
    </h4>
    <p>
      I took my Genuine Blur scooter from Seattle to the California Redwoods and back along Hwy 101.
      2 days down, 2 days in the area, and 2 days back up. No windshield, no throttle lock, lots of
      pain, no regrets.
    </p>
    <RedwoodsGallery />
  </>
);
