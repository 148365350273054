import React from 'react';

import { Month08 } from './08/_index';
import { Month07 } from './07/_index';

export const Year2020: React.FC = () => (
  <>
    <h2 className="timeline__event">2020</h2>
    <Month08 />
    <Month07 />
  </>
);
