import React from 'react';

import { Gallery } from 'src/components/gallery/gallery';

import videoPoster from './video.webp';
import video from './video.webm';
// Images are larger when converted to webp, somehow
import img1 from './1.jpg';
import img2 from './2.jpg';

export const MtRainier: React.FC = () => (
  <>
    <h4 className="timeline__event" id="2020-08-mt-rainier">
      Mt. Rainier - Paradise
    </h4>
    <p>Taking the Buddy 125 up to Paradise.</p>
    <Gallery
      photos={[
        {
          src: videoPoster,
          width: 16,
          height: 7,
          alt:
            'Plays video of a first-person scooter ride through Paradise in Mt. Rainier. Set to soothing piano music.',
          video: { src: video },
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img1,
          width: 4,
          height: 3,
          alt:
            'Picture of a Genuine Buddy scooter on the shoulder of a curving mountain road, set against a forest of pine trees and brilliant blue sky with some cirrus clouds',
          tabIndex: 1,
          loading: 'lazy',
        },
        {
          src: img2,
          width: 4,
          height: 3,
          alt:
            'Front view of my white Genuine Buddy scooter on the same road shoulder, with towering forest pine trees as a backdrop',
          tabIndex: 1,
          loading: 'lazy',
        },
      ]}
    />
  </>
);
